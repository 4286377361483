.c-sidebar-nav {
  list-style: none;
  /* margin-top: rem($global-spacing/2);*/
  padding-left: 0;

  @include bp-lg {
    /*position: fixed;*/
    text-align: right;
  }
  &__item {
    display: inline-block;
    margin-right: 1em;
    @include bp-lg {
      display: list-item;
      margin-right: auto;
    }
  }
  &__link {
    @extend .text--headingsmall;
    @extend .text--primary;
    @extend .text--extrabold;
    color: $color-primary;
  }

  &__item--active {
    .c-sidebar-nav__link {
      color: $color-black !important;
    }
  }
}
