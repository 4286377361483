.c-gallery {
  @include grid-row;

  &__album {
    @extend %c-thumb;
    &:after {
      @extend .u-mt--half;
      content: "";
      display: block;
      height: 7px;
      width: 100%;
      background-color: $color-blue-transparent;
    }
  }

  &__item {
    @extend %c-thumb;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  &__photo {
    @extend %c-thumb__image;
  }

  &__coverphoto {
    @extend %c-thumb__image;
    @extend .u-mb--half;
  }

  &__title {
    @extend %c-thumb__caption;
  }
}
