.c-section {
  @extend .u-pb;
  @extend .u-pt;
  padding-bottom: rem($global-spacing);
  &__container {
    @include grid-container;
    position: relative;
  }
  &__row {
    @include grid-row;
  }
  &__header {
    @extend %c-headline;
  }
  &__title {
    @extend %c-headline__title;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
  }
  &__icon-news {
    position: absolute;
    left: cp(955, 1140);
    top: 45px;
  }
}
