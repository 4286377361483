///* ========================================================================
//   #FLEX UTILITIES
//   ======================================================================== */

@mixin flex() {
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-horizontal() {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-vertical() {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-horizontal-center() {
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-horizontal-left() {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-horizontal-right() {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flex-horizontal-justify() {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-horizontal-distribute() {
  -ms-flex-pack: justify;
  justify-content: space-around;
}

@mixin flex-vertical-center() {
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-vertical-bottom() {
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-vertical-stretch() {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@mixin flex-vertical-top() {
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-wrap() {
  flex-wrap: wrap;
}

@mixin flex-nowrap() {
  flex-wrap: nowrap;
}

%flex {
  @include flex();
}

%flex-horizontal {
  @include flex-horizontal();
}

%flex-vertical {
  @include flex-vertical();
}

%flex-horizontal-center {
  @include flex-horizontal-center();
}

%flex-horizontal-left {
  @include flex-horizontal-left();
}

%flex-horizontal-right {
  @include flex-horizontal-right();
}

%flex-horizontal-justify {
  @include flex-horizontal-justify();
}

%flex-horizontal-distribute {
  @include flex-horizontal-distribute();
}

%flex-vertical-center {
  @include flex-vertical-center();
}

%flex-vertical-bottom {
  @include flex-vertical-bottom();
}

%flex-vertical-stretch {
  @include flex-vertical-stretch();
}

%flex-vertical-top {
  @include flex-vertical-top();
}

%flex-wrap {
  @include flex-wrap();
}

%flex-nowrap {
  @include flex-nowrap();
}
