.c-footer-form {
  input[type=text],
  input[type=email],
  input[type=number],
  input[type=button],
  input[type=submit],
  textarea,
  select {
    &.c-footer-form__input {
      @extend .u-mb--quater;
      @extend .u-pl--quater;
      @extend .text--primary;
      @extend .text--caps;
      border-color: $color-white;
      background-color: transparent;
      border-radius: 25px;
      border-width: 2px;
      border-style: solid;
    }
  }

  input[type=text],
  input[type=email],
  input[type=number],
  input[type=button],
  input[type=submit],
  select {
    &.c-footer-form__input {
      height: rem(50);
    }
  }

  input[type=text],
  input[type=email],
  input[type=number],
  input[type=button],
  input[type=submit],
  textarea {
    &.c-footer-form__input {
      color: $color-white;
    }
  }

  select {
    &.c-footer-form__input {
      color: #757575;
    }
  }

  button,
  input[type=submit] {
    &.c-footer-form__input {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .selectr-selected {
    @extend .u-pl--quater;
    @extend .u-mb--quater;
    @extend .text--primary;
    @extend .text--caps;
    color: #757575;
    border-width: 2px;
    border-color: $color-white;
    background-color: transparent;
    border-radius: 25px;
  }

  label {
    @extend .text--utility;
    color: $color-white;

    a {
      color: $color-white;
      text-decoration: underline;
    }
  }

  input[type=submit]:disabled,
  button:disabled {
    opacity: 0.3;
    cursor: auto;
    &:hover {
      opacity: 0.3;
    }
  }
}
