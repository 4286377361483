.c-tabs {
  @include list-reset;
  margin-bottom: rem($global-spacing);
  @include bp-md {
    margin-left: -(rem($global-spacing/10));
    margin-right: -(rem($global-spacing/10));
  }
  &__item {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    text-align: center; /* [4] */
    cursor: pointer; /* [5] */
    width: 100%;
    margin-bottom: 0.5em;
    @include bp-md {
      width: auto;
      margin-left: rem($global-spacing/10);
      margin-right: rem($global-spacing/10);
    }
  }
  &__link {
    @extend .text--primary;
    @extend .text--copysmall;
    @extend .text--extrabold;
    @extend .text--caps;
    display: inline-block;
    width: 100%;
    color: $color-blue;
    background-color: $color-blue-light;
    padding: rem(8) rem($global-spacing/4);
    transition: background-color 0.3s ease-out;
    border-radius: rem(50);
    @include bp-md {
      padding: rem(19) rem($global-spacing/4);
    }
    &.active {
      color: $color-white;
      background-color: $color-yellow;
    }
    @include bp-md {
      &:active {
        color: $color-white;
        background-color: $color-yellow;
      }
      &:hover {
        color: $color-white;
        background-color: $color-blue;
      }
    }
  }
}
