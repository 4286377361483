.c-events {
  @extend .u-mb;
  &__container {
    @include grid-container;
    position: relative;
  }
  &__icon {
    position: absolute;
    left: cp((960), 1140);
    top: -150px;
    z-index: -1;
  }
  &__headline {
    @extend %c-headline;
  }
  &__title {
    @extend %c-headline__title;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
  }

  &__list {
    @include grid-row;
    @extend .u-mb;
  }

  &__item {
    @extend .u-mb--half;
    @include grid-column(12);
    @include bp-md() {
      @include grid-column(4);
    }
    @include bp-lg {
      margin-bottom: 0;
    }
    @include bp-xl {
      @include grid-column(4);
    }
  }

  &__buttons {
    @extend %o-buttons;
    text-align: center;

    li {
      @extend %o-buttons__item;
    }
  }
}
