/* ==========================================================================
   #Tippy theme
   ========================================================================== */

.tippy-tooltip.isob-theme {
  @extend .text--primary;
  @extend .text--extrabold;
  @extend .text--headingsmall;
  background-color: $color-blue;
  border: none;
  color: $color-white;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.isob-theme .tippy-roundarrow,
.tippy-popper[x-placement^=bottom] .tippy-tooltip.isob-theme .tippy-roundarrow {
  svg {
    path {
      fill: $color-blue;
    }
  }
}
