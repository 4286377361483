.c-news {
  @include grid-row;
  &:hover {
    transition: opacity 0.3s ease-out;
    .c-news__item {
      opacity: 0.8;
      transition: opacity 0.2s ease-out;
    }
  }
  &__item {
    @extend %c-thumb;
    &:hover {
      opacity: 1 !important;
    }
    &:after {
      @extend .u-mt--half;
      content: "";
      display: block;
      height: 7px;
      width: 100%;
      background-color: $color-blue-transparent;
    }
  }

  &__date,
  &__title,
  &__content {
    display: block;
  }
  &__date,
  &__title {
    @extend .u-mb--half;
    @extend .text--primary;
    @extend .text--extrabold;
    line-height: 1.3;
  }
  &__date {
    @extend .text--utility;
    @extend .text--caps;
    @extend .text--spaced;
    color: $color-blue;
  }
  &__title {
    @extend .text--heading;
    &:first-line {
      line-height: 1;
    }

    color: $color-black;
  }
  &__image {
    @extend %c-thumb__image;
    @extend .u-mb--half;
  }
  &__content {
    @extend .text--copysmall;
    color: $color-black;
  }
}
