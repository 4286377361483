/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include grid-container;

  &__section {
    @include grid-row;
    @extend .u-mb;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &--full {
    .c-post__content {
      @include grid-column(12);
    }
  }

  &__content {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(8);
    }
  }

  &__attachments {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(4);
    }
  }
  &__headline {
    @extend %c-headline;
    @extend .u-mb;
    text-align: left;
    &--short {
      @include grid-column(12);
      @include bp-lg {
        @include grid-column(4);
      }
    }
  }

  &__sneakpeek {
    @include grid-column(12);
    @extend .u-mt;
    @include bp-lg {
      @include grid-column(8);
      padding-top: rem(7);
    }
  }

  &__title {
    @extend %c-headline__title;
    margin-bottom: 0;
  }
  &__subtitle {
    @extend .text--primary;
    @extend .text--headinglarge;
    @extend .text--extrabold;
    @extend .u-mb;
    color: $color-blue;
  }
  &__parent {
    @extend %c-headline__subtitle;
  }
  h2 {
    @extend .text--primary;
    @extend .text--headinglarge;
    @extend .text--extrabold;
    @extend .u-mb;
    color: $color-blue;
  }
  p + h2 {
    @extend .u-pt;
    margin-top: -1rem;

  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
