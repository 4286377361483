/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  height: 1px;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}
