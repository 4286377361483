.c-fmember {
  @include grid-row;
  @extend .u-pb;


  &__details {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(12);
      @include flex;
      @include flex-vertical;
      order: 1;
    }
  }

  &__contact {
    @include grid-row;
    @extend .u-mb;
    @include bp-lg {
      width: 100%;
      order: 1;
    }
  }

  &__col {
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(4);
    }
    &--full {
      @include bp-lg {
        @include grid-column(8);
      }
    }
  }

  &__bio {
    @include grid-row;
    @include bp-lg {
      width: 100%;
      order: 2;
    }
  }

  &__header {
    @include grid-column(12);
    position: relative;
    margin-bottom: 1em;
    @include bp-lg {
      @include grid-column(3);
      margin-left: 8.333333%; // offset
      order: 2;
    }
  }
  &__thumb {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    @extend .u-mb;
    @include bp-lg {
      width: 100%;
      position: absolute;
      transform: translateY(-100%);
      margin-top: -(rem($global-spacing));
      margin-bottom: 0;
    }
    & > * {
      @extend .c-family__photo;
      width: 100%;
      &:hover {
        background-image: none;
      }
    }
  }

  &__name,
  &__role {
    @extend .text--primary;
    @extend .text--heading;
    @extend .text--extrabold;
    line-height: (36/24);
  }

  &__role {
    color: $color-blue;
  }

  &__email,
  &__telephone {
    @extend .text--primary;
    @extend .text--extrabold;
    @extend .text--copy;

    @include bp-lg {
      margin-bottom: 0;
    }
  }
}
