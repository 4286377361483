.c-hero {
  position: relative;
  text-align: center;

  br {
    display: none;
    @include bp-lg {
      display: block;
    }
  }

  @include bp-lg {
    text-align: inherit;
  }

  &__row {
    @include grid-row;
  }
  &__headline {
    @extend %c-headline;
    @extend .u-mb;
    max-width: 100%;
    z-index: 10;
    @include bp-lg {
      @include grid-row;
    }
  }

  &__title {
    @extend %c-headline__title;
    margin-bottom: 0;
    padding-left: rem($global-spacing/4);
    padding-right: rem($global-spacing/4);
    text-transform: none;
    @include bp-lg {
      @include grid-column(6);
    }
  }

  &__subtitle {
    @extend %c-headline__subtitle;
    padding-left: rem($global-spacing/4);
    padding-right: rem($global-spacing/4);
    @include bp-lg {
      @include grid-column(12);
      font-size: rem(27) !important;
    }
  }

  &__frame {
    $width: 100% /3 * 1.5;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include bp-lg {
      width: $width;
      min-width: $width;
      flex-basis: 0 0 $width;
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100% * 0.66666667;
    }
  }
  &__image {
    width: 100%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__buttons {
    @extend %o-buttons;

    li {
      @extend %o-buttons__item;
    }
  }
/*
  &__image {
    @include bp-lg {
      position: absolute;
      top: -55px;
      right: -(cp(43, 1920));
      width: cp(1277, 1920);
      z-index: -3;
    }
  }
*/
  &__pag {
    @include grid-container;
    @extend .u-mt;
    position: relative;
    z-index: 200;
  }

  @include bp-lg {
    &__container {
      @include grid-container;
    }
    &__slide {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    &__container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }
  }




}
