.c-attachments {
  @include bp-lg {
    &--right {
      text-align: right;
    }
  }
  &__title {
    @extend .u-mt;
    @extend .text--primary;
    @extend .text--heading;
    @extend .text--extrabold;
    line-height: (36/24);
    color: $color-black;
    &--blue {
      color: $color-blue;
    }
    @include bp-lg {
      margin-top: 0;
    }
  }
}
