.c-programmes {
  display: flex;
  align-items: stretch;
  &__item {
    margin-bottom: rem($global-spacing/2);
    @include bp-lg {
      display: flex;
      align-items: stretch;
      margin-bottom: 0;
    }
  }
}
