@import 'swiper/src/swiper.scss';
@import 'swiper/src/components/pagination/pagination.scss';
@import 'swiper/src/components/navigation/navigation.scss';
/*
.c-hero__frame {
  transform: translateX(10%);
  transition: transform 500ms $global-transition-easing;
}
.swiper-slide-active {
  .c-hero__frame {
    transform: translateX(0);
  }
}
*/

.swiper-pagination {
  @include bp-lg {
    @include grid-container;
    text-align: right;
    left: 50% !important;
    transform: translate3d(-50%, 0, 0);
  }
}
.swiper-pagination-bullet {
  @include bp-lg {
    width: 12px;
    height: 12px;
  }
}
.swiper-pagination-bullet-active {
  background-color: transparent;
  border: 2px solid $color-primary;
}
