.c-programme {
  display: block;
  width: 100%;
  transition: transform $global-transition-time $global-transition-easing;
  @include bp-lg {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: translateY(-15%);
    }
  }
  &--text {
    border: 1px solid $color-primary;
    border-radius: $global-radius;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__label {
    @extend .text--primary;
    @extend .text--extrabold;
    @extend .text--headingsmall;
    display: block;
    text-align: center;
  }
}
