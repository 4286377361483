.c-family {
  @include grid-row;

  &__member {
    @include grid-column(12);
    @extend .u-mb--half;
    @include bp-sm {
      @include grid-column(6);
    }
    @include bp-md {
      @include grid-column(4);
    }
    @include bp-lg {
      @include grid-column(3);
    }
  }
  &__frame {
    @extend .u-mb--half;
    @extend .u-pl--half;
    @extend .u-pr--half;
  }
  &__photo {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    &--default {
      background-image: url(../assets/images/misc_family-placeholder.svg) !important;
    }
    &:not(.c-fmember__photo) {
      @include bp-lg {
        &:hover {
          background-color: $color-yellow;
          background-image: url(../assets/images/ico_card.svg) !important;
          background-position: center center;
          background-size: auto;
        }
      }
    }


    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  &__name,
  &__role {
    @extend .text--primary;
    @extend .text--copy;
    @extend .text--extrabold;
    text-align: center;
    line-height: 1.25;
  }
  &__name {
    margin-bottom: 0.25em;
  }
  &__role {
    color: $color-blue;
  }

}
