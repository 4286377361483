/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
@mixin btn-hover {
  /*
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: rem(50);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  &:hover,
  &:focus {
    &:after {
      opacity: 1;
    }
    // box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
  }
  */

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}

.c-btn {
  @include btn-hover;
  @extend .text--primary;
  @extend .text--copysmall;
  @extend .text--extrabold;
  @extend .text--caps;
  display: inline-block; /* [1] */
  min-width: rem(230);
  vertical-align: middle; /* [2] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: rem(19) rem($global-spacing/4);
  transition: background-color 0.3s ease-out;
  border-radius: rem(50);
  @include bp-lg {
    padding: rem(19) rem($global-spacing/2);
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-primary;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }
/*
  &:hover,
  &:focus {
    background-color: rgba($color-hover, 0.8);
  }
*/
}

.c-btn--secondary {
  background-color: transparent;
  border: 2px solid $color-blue;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }
/*
  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-blue;
  }
*/
}

.c-btn--yellow {
  background-color: $color-yellow;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }
/*
  &:hover,
  &:focus {
    background-color: rgba($color-yellow, 0.8);
  }
*/
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  @extend .text--secondary;
  @extend .text--utility;
  @extend .text--regular;
  min-width: 0;
  padding: rem(10) rem(40) rem(13) rem(40);;
  line-height: 1;
  text-transform: none;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}
