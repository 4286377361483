@mixin list-reset() {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

%list-reset {
  @include list-reset();
}
