.u-table-fixed {
  @include bp-lg {
    td {
      min-width: 100px;
    }
    tr th:first-child,
    tr td:first-child  {
      width: 50px;
      min-width: 0;
    }
    tr th:nth-child(2),
    tr td:nth-child(2)  {
      width: 70px;
      min-width: 0;
    }
  }
}
