@keyframes rightToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30px);
  }
}

.u-table,
.u-table-overflow-mobile {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;

  tr th:first-child {
    font-size: 0;
    &:after {
      content: "L.";
      font-size: 1.3125rem;
    }
  }

  &__indicator {
    position: absolute;
    right: 0;
    width: 32px;
    animation-duration: 1500ms;
    animation-delay: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.17,0.67,0.2,1.37);
    animation-name: rightToLeft;
    z-index: 77;
    &:after {
      transform: translateY(5px);
      content: "";
      display: block;
      padding-bottom: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      background-image: url(../assets/images/ico_swipe.svg);
    }

    @include bp-lg {
      width: 46px;
      &:after {
        background-image: url(../assets/images/ico_scroll.svg);
      }
    }
  }
}
