body {
  @extend .text--copy;
  background-repeat: no-repeat;
  overflow-x: hidden;
  &.admin-bar {
    // position: relative;
  }
  &.post-type-archive-family,
  &.page-template-template-news {
    background-position: cp(210,1920) rem(260);
    background-image: url(../assets/images/bg_family.svg);

  }
  &.page-template-default:not(.home) {
    background-position: center rem(207);
    background-image: url(../assets/images/bg_text.svg);
  }
}
