.l-top {
  @extend .container;
  @extend %flex;
  @include flex-horizontal-justify();
  @include flex-vertical-center();
  flex-wrap: wrap;
  @include bp-lg {
    /*height: rem(100);*/
    flex-wrap: nowrap;
  }
  &__logos {
    @include flex;
    @include flex-horizontal-justify;
    max-width: 60%;
    @include bp-sm {
      max-width: none;
    }
  }
}
