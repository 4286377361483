/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

$utility-spacing: $global-spacing;

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-pt {
  padding-top: rem($utility-spacing/2);
  @include bp-lg {
    padding-top: rem($utility-spacing);
  }
  @include bp-lg {
    &\@lg {
      padding-top: rem($utility-spacing) !important;
    }
  }
  @include bp-md {
    &\@md {
      padding-top: rem($utility-spacing) !important;
    }
  }
  &--2x {
    @include bp-lg {
      &\@lg {
        padding-top: rem($utility-spacing*2);
      }
    }
  }
}

.u-pb {
  padding-bottom: rem($utility-spacing/2);
  @include bp-lg {
    padding-bottom: rem($utility-spacing);
  }
  &--double {
    padding-bottom: rem($utility-spacing*2);
  }
  &--half {
    padding-bottom: rem($utility-spacing/2);
  }
  &--quater {
    padding-bottom: rem($utility-spacing/4);
  }
}

.u-pr {
  padding-right: rem($utility-spacing);
  &--half {
    padding-right: rem($utility-spacing/2);
  }
  &--quater {
    padding-right: rem($utility-spacing/4);
  }
}

.u-pl {
  padding-left: rem($utility-spacing);
  &--half {
    padding-left: rem($utility-spacing/2);
  }
  &--quater {
    padding-left: rem($utility-spacing/4);
  }
}

.u-pt--half {
  padding-top: rem($utility-spacing/2);
}

.u-pt--quater {
  padding-top: rem($utility-spacing/4);
}

.u-mt {
  margin-top: rem($utility-spacing/2);
  @include bp-lg {
    margin-top: rem($utility-spacing);
  }
  &--half {
    margin-top: rem($utility-spacing/2);
  }
  &--quater {
    margin-top: rem($utility-spacing/4);
  }
  &--oneandahalf {
    margin-top: rem($global-spacing/2);
    @include bp-lg {
      margin-top: rem($global-spacing*1.5);
    }
  }
}

.u-mb {
  margin-bottom: rem($utility-spacing/2);
  @include bp-lg {
    margin-bottom: rem($utility-spacing);
  }

  @include bp-xs {
    &\@xs {
      margin-bottom: rem($utility-spacing);
    }
  }
  @include bp-lg {
    &--0\@lg {
      margin-bottom: 0;
    }
  }
  &--half {
    margin-bottom: rem($utility-spacing/2);
    @include bp-xs {
      &\@xs {
        margin-bottom: rem($utility-spacing);
      }
    }
  }
  &--quater {
    margin-bottom: rem($utility-spacing/4);
  }
}

.u-mr {
  margin-right: rem($utility-spacing);
  &--half {
    margin-right: rem($utility-spacing/2);
  }
  &--quater {
    margin-right: rem($utility-spacing/4);
  }
}

.u-ml {
  margin-left: rem($utility-spacing);
  &--half {
    margin-left: rem($utility-spacing/2);
  }
  &--quater {
    margin-left: rem($utility-spacing/4);
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media screen and (max-width: #{$breakpoint}) {
    .#{$breakpoint-name}-mb {
      margin-bottom: rem($utility-spacing/2);
    }
  }

}
