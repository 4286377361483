.c-files {
  @include list-reset;
  &__item {
    margin-bottom: 1em;
  }
  &__link {
    @extend .text--primary;
    @extend .text--copy;
    @extend .text--extrabold;
  }
  &__filename {
    @extend .text--utility;
    @extend .text--regular;
    display: block;
    color: rgba($color-blue, 0.4);
  }
}
