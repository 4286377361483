/**
 * Module: Type
 *
 * @author: Krzysztof Malina
 */

@import '../tools/breakpoints';

$font-families: ()!default;
$font-weights: ()!default;
$font-family-primary: 'Muli', sans-serif;
$font-family-secondary:'Lato', sans-serif;
$font-family: $font-family-secondary;

$font-weights: (
  regular: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800
);

$font-sizes: (
  callout:         72,
  calloutsmall:    48,
  headinglarge:    36,
  heading:         24,
  headingsmall:    21,
  copy:            18,
  copysmall:       16,
  utility:         14
);

.text {
  -ms-word-break: break-word;
  font-style: normal;
  font-stretch: normal;

  &--callout {
    font-size: rem(30);
    @include bp-md {
      font-size: rem(72);
    }
  }

  &--calloutsmall {
    font-size: rem(30);
    @include bp-md {
      font-size: rem(48);
    }
  }

  &--headinglarge {
    font-size: rem(24);
    @include bp-md {
      font-size: rem(36);
    }
  }

  &--heading {
    font-size: rem(24);
  }

  &--headingsmall {
    font-size: rem(21);
  }
  &--copy {
    font-size: rem(16);
    @include bp-md {
      font-size: rem(18);
    }
  }

  &--copysmall {
    font-size: rem(16)
  }

  &--utility {
    font-size: rem(14);
  }

  &--primary {
    font-family: $font-family-primary;
  }

  &--secondary {
    font-family: $font-family-secondary;
  }

  &--caps {
    text-transform: uppercase;
  }

  &--lower {
    text-transform: lowercase;
  }

  &--spaced {
    letter-spacing: letterSpacing(300);
  }

  @each $color-name, $color in $colors {
    &--#{$color-name} {
      color: $color !important;
    }
  }

  @each $font-family-name, $font-family in $font-families {
    &--#{$font-family-name} {
      font-family: $font-family, sans-serif !important;
    }
  }

  @each $font-weight-name, $font-weight in $font-weights {
    &--#{$font-weight-name} {
      font-weight: $font-weight !important;
    }
  }

}
