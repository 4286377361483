%c-headline {
  text-align: center;
  @include bp-lg {
    text-align: inherit;
  }
  &__subtitle {
    @extend .text--primary;
    @extend .text--headingsmall; // size
    @extend .text--caps; // behaviour
    @extend .text--spaced; // behaviour
    @extend .text--extrabold;
    @extend .u-mb--quater;
    color: $color-primary;
  }
  &__title {
    @extend .text--primary;
    @extend .text--callout; // size
    @extend .text--extrabold;
    @extend .text--lower;
    @extend .u-mb;
    margin-top: 0;
    line-height: 1.2;
    color: $color-black;
  }
//  @include bp-md {
    &:before {
      margin-bottom: rem($global-spacing/2);
      content: "";
      display: inline-block;
      width: 270px;
      max-width: 100%;
      height: 7px;
      margin-top: 7px;
      background-color: $color-blue-transparent;
    }
//  }
}
