.c-event {
  padding-top: rem($global-spacing/3);
  padding-bottom: rem($global-spacing/3);
  text-align: center;
  background-color: $color-white;
  border-radius: $global-border-radius;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.1);

  &__date,
  &__title,
  &__content {
    margin-left: rem($global-spacing/3);
    margin-right: rem($global-spacing/3);
    text-align: left;
  }

  &__date {
    @extend .u-mb--half;
    @extend .text--primary;
    @extend .text--utility;
    @extend .text--bold;
    @extend .text--caps;
    @extend .text--spaced;
    display: block;
    color: $color-blue;
  }

  &__title {
    @extend .u-mb--half;
    @extend .text--primary;
    @extend .text--heading;
    @extend .text--extrabold;
    line-height: 1.35;
  }

  &__content {
    @extend .u-mb--half;
  }
}
