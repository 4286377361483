.c-pag {
  @include flex;
  @include flex-horizontal-center;
  @include list-reset;
  @extend .u-pt--half;
  @extend .u-pb;
  &__item {
    @include grid-column(1);
  }
  &__link,
  &__label {
    @extend .text--primary;
    @extend .text--heading;
    @extend .text--extrabold;
    display: block;
    text-align: center;
  }
  &__link {
    color: $color-black;
    &:hover {
      background-color: $color-blue;
      color: $color-white;
      border-radius: $global-border-radius;
    }
  }
  &__label {
    @extend .text--blue;
  }
}
