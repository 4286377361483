.c-footer-nav {
  @include list-reset();
  @include flex();
  @include flex-horizontal-center;

  @include bp-lg {
    @include flex-horizontal-left;
  }

  &__item {
    @include flex();
    @include flex-vertical-stretch;
  }
  &__link {
    @include flex();
    @include flex-vertical-center;
    @extend .u-pl--quater;
    @extend .u-pr--quater;
    @extend .text--primary;
    @extend .text--copysmall;
    @extend .text--extrabold;
    @extend .text--caps;
    color: $color-gray-lighter;
    &:hover {
      color: $color-gray-light;
    }
  }
}
