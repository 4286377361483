.u-table-even {
  @include bp-lg {
    table-layout : fixed;
    tr th:first-child,
    tr td:first-child  {
      width: 50px;
    }
    tr th:nth-child(2),
    tr td:nth-child(2)  {
      width: 70px;
    }
  }
}
