.c-fmly {
  text-align: center;
  @include bp-lg {
    text-align: right;
  }
  &__container {
    @include grid-container;
  }
  &__row {
    @include grid-row;
  }
  &__photo {
    @include grid-column(12);
    @include bp-md {
      @include grid-column(7);
    }
  }
  &__copy {
    @include grid-column(12);
    @include bp-md {
      @include grid-column(5);
    }
  }
  &__headline {
    @extend %c-headline;
  }
  &__title {
    @extend .u-mb;
    @extend %c-headline__title;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
  }
  &__desc {
    @extend .u-mb;
  }

}
