.c-cookies {
  display: none;
  &--on {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: rem($utility-spacing/8);
    padding-bottom: rem($utility-spacing/12);
    z-index: 9999;
    color: $color-blue-light;
    background-color: $color-blue;
  }
  &__content {
    margin-bottom: 1em;
    font-size: rem(14);
    @include bp-md {
      margin-bottom: 0;
      padding-right: 10%;
    }
  }
  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    &:after {
      @extend .far;
      content: "\f410";
      font-size: rem(36);
      color: $color-blue-light;
    }
  }
}
