.c-facts {
  @extend .u-mb;
  text-align: center;
  @include bp-lg {
    text-align: right;
  }
  &__container {
    @include grid-container;
    position: relative;
  }
  &__icon {
    @include bp-md {
      position: absolute;
      left: -(cp((62+15), 1140));
      top: 62px;
      z-index: -1;
    }
  }
  &__headline {
    @extend %c-headline;
  }
  &__title {
    @extend %c-headline__title;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
  }
  &__numbers,
  &__facilities {
    @include grid-row;
  }
  &__numbers {
    @extend .u-mb;
  }
  &__heading {
    @extend .text--primary;
    @extend .text--extrabold;
    @extend .text--headingsmall;
    margin-bottom: rem($global-spacing/2);
    text-align: center;
  }

}
