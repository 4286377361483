.c-get-started {
  @extend .u-mb;
  @extend .u-mt--oneandahalf;
  position: relative;
  &__container {
    @include grid-container;
    position: relative;
  }
  &__icon {
    display: none;
    @include bp-lg {
      display: block;
      position: absolute;
      right: cp(742, 1140);
      top: -130px;
      z-index: -1;
    }
  }
  &__headline {
    @extend %c-headline;
    &:before {
      content: none !important;
    }
  }
  &__title {
    @extend %c-headline__title;
  }
  &__buttons {
    @extend %o-buttons;

    li {
      @extend %o-buttons__item;
    }
  }
}
