.c-main-nav {
  @extend .text--copy;
  display: none;
  flex: 0 0 100%;
  max-width: 100%;
  font-family: $font-family-primary;
  font-weight: 800;

  @include respond-up-to(lg) {
    &--is-expanded {
      @include flex;
      @include flex-vertical();
      padding-top: rem($global-spacing/2);
    }
  }

  @include bp-lg {
    @include flex;
    @include flex-horizontal();
    @include flex-horizontal-justify();
    -ms-flex-item-align: stretch;
    flex-wrap: nowrap;
    align-self: stretch;
    flex: 0 1 auto;
    max-width: none;
  }

  &__row {
    @include bp-lg {
      @include grid-row;
    }
  }

  &__col--3 {
    @include bp-lg {
      @include grid-column(3);
    }
  }
  &__col--4 {
    @include bp-lg {
      @include grid-column(4);
    }
  }
  &__col--5{
    @include bp-lg {
      @include grid-column(5);
    }
  }
  &__col--6 {
    @include bp-lg {
      @include grid-column(6);
    }
  }

  &__item {
    @include bp-lg {
      @include flex;
      @include flex-vertical-stretch;

      &:first-child {
        .c-main-nav__link {
          padding-left: 0;
        }
      }
      &:last-child {
        .c-main-nav__link {
          padding-right: 0;
        }
      }

      &:hover .c-main-nav__dropdown {
        display: flex;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
        background-color: $color-blue-light;
      }
    }
  }

  &__link {
    @include bp-lg {
      @include flex;
      @include flex-vertical-center();
      color: $color-black;
      @include bp-lg {
        padding-left: rem(10);
        padding-right: rem(10);
      }
      @include bp-xl {
        padding-left: rem(30);
        padding-right: rem(30);
      }
    }
  }

  &__btn {
    display: none;
  }

  &__dropdown {
    @include bp-lg {
      position: absolute;
      top: 100%;
      padding-top: rem($global-spacing/2);
      padding-bottom: rem($global-spacing/2);
      //display: none;
      flex-direction: column;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateY(30px);
      transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
    }
  }

  &__subitem {
    @include respond-up-to(lg) {
      width: 100% !important;
      max-width: none !important;
      display: block;
      flex: auto;
    }
    margin: 0;
  }

  @include bp-lg {
    .c-main-nav__sublink {
      color: $color-black;
    }
    .c-main-nav__grandlink {
      font-family: $font-family-secondary;
      font-size: rem(16);
      font-weight: 600;
      color: $color-blue;
    }
    .c-mega-nav {
      .c-main-nav__sublink {
        padding-bottom: rem($global-spacing/4);
        margin-bottom: rem($global-spacing/4);
        border-bottom: 6px solid $color-white;
      }
    }
  }

  &__sublink,
  &__grandlink {
    display: block;
    padding-top: 0.5em;
    text-decoration: none;
    @include bp-lg {
      margin-left: rem($global-spacing/2);
      margin-right: rem($global-spacing/2);
    }
  }

  a.c-main-nav__sublink,
  a.c-main-nav__grandlink {
    @include bp-lg {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  @include bp-lg() {
    &__subitem--pyp > .c-main-nav__sublink,
    &__subitem--myp > .c-main-nav__sublink,
    &__subitem--dp > .c-main-nav__sublink {
      width: 75%;
      display: block;
      font-size: 0;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      &:after {
        content: "";
        display: block;
        padding-bottom: 30.5%;
      }
    }
    &__subitem--pyp > .c-main-nav__sublink {
      background-image: url(../assets/images/pyp-programme-logo-en.png)
    }
    &__subitem--myp > .c-main-nav__sublink {
      background-image: url(../assets/images/myp-programme-logo-en.png)
    }
    &__subitem--dp > .c-main-nav__sublink {
      background-image: url(../assets/images/dp-programme-logo-en.png)
    }
  }

  @include respond-up-to(lg) {
    &__item,
    &__subitem {
      position: relative;
      &.menu-item-has-children {
        & > a {
          padding-right: (48px + 15px);
          //background: red;
        }
      }
    }

    &__btn {
      display: block;
      position: absolute;
      right: 15px;
      top: rem($utility-spacing/6);
      width: 42px;
      height: 42px;
      outline: 0;
      border: 2px solid $color-blue;
      background-color: transparent;
      border-radius:55px;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        content: "\f107";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: rem(18);
        line-height: 1;
        color: $color-blue;
        vertical-align: middle;
      }
      &--is-clicked {
        &:after {
          content: '\f106';
        }
      }
    }
  }

}

.c-main-nav {
  &,
  ul {
    @include list-reset;
  }
  li {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
  @include respond-up-to(lg) {
    ul {
      width: 100%;
    }
    ul {
      display: none;
      &.is-expanded {
        display: block;
      }
    }
    a,
    span {
      @include flex;
      @include flex-horizontal;
      @include flex-vertical-center;
      width: 100%;
      padding-top: rem($utility-spacing/5);
      padding-bottom: rem($utility-spacing/5);
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid $color-blue-transparent;
    }
    li {
      position: relative;
      min-height: 63px;
    }
    li:hover ul {
      // display: block;
    }
  }
}
