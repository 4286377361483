/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  transition: color 200ms $global-transition-easing;
  &:hover {
    color: $color-hover;
  }
}
