.c-cons {
  @include grid-row;
  &__list {
    @include list-reset;
    @include flex;
    @include flex-horizontal-distribute;
    width: 100%;
    flex-wrap: wrap;
  }
  &__large {
    @include grid-column(12);
    @extend .u-mb--half;
    @include bp-lg {
      @include grid-column(7);
      margin-bottom: 0;
    }
  }
  &__small {
    @include grid-column(12);
    @include flex;
    @include flex-vertical-stretch;
    @include bp-lg {
      @include grid-column(5);
    }
  }
  &__item {
    @include flex;
    @include flex-vertical-center;
    @include flex-horizontal-center;
    text-align: center;
    img {
      max-width: 80%;
      height: auto;
    }
    &--lg {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 25%;
      max-width: 25%;
    }
    &--sm {
      @extend .u-mb--half;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: (100%/5);
      max-width: (100%/5);
      @include bp-lg {
        margin-bottom: 0;
      }
    }
  }
}
