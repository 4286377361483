%c-thumb {
  @include grid-column(12);
  @extend .u-mb--half;
  @include bp-sm {
    @include grid-column(6);
  }
  @include bp-md {
    @include grid-column(4);
  }

  &__image {
    max-width: 100%;
    border-radius: $global-border-radius;
  }

  &__caption {
    @extend .text--primary;
    @extend .text--heading;
    @extend .text--extrabold;
    line-height: 1;
  }
}

.grid-sizer {
  @include grid-column(12);
  @include bp-sm {
    @include grid-column(6);
  }
  @include bp-md {
    @include grid-column(4);
  }
}
