.c-fact {
  @extend .u-mb--half;
  @include grid-column(12);
  @include bp-sm {
    @include grid-column(6);
  }
  @include bp-lg {
    @include grid-column(3);
    margin-bottom: 0;
  }
  text-align: center;

  &__icon {
    @extend .u-mb--quater;
    @include flex;
    @include flex-vertical-bottom;
    @include flex-horizontal-center;
  }

  &__desc,
  &__number {
    @extend .text--primary;
    @extend .text--extrabold;
  }
  &__desc {
    @extend .u-mb--quater;
    @extend .text--headingsmall;
  }
  &__number {
    @extend .text--callout;
    margin-bottom: 0;
    line-height: 1;
    color: $color-blue;
  }
}
