.c-loc {
  &__text {
    @extend .u-pb;
    position: relative;
  }
  &__b {
    @extend .text--headingsmall;
    font-weight: bold;
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  &__container {
    @include grid-container;
  }

  &__row {
    @include grid-row;
    @include flex-vertical-bottom;
  }

  &__headline {
    @extend .u-mb;
    @extend %c-headline;
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(4);
      margin-bottom: 0;
    }
    @include bp-xl {
      @include grid-column(6);
    }
  }

  &__address,
  &__contact,
  &__item {
    text-align: center;
    @include grid-column(12);
    @include bp-lg {
      @include grid-column(4);
      text-align: inherit;
    }
    @include bp-xl {
      @include grid-column(3);
    }
    p {
      margin-bottom: 0;
    }
  }

  &__title {
    @extend %c-headline__title;
    margin-bottom: 0;
    line-height: 1;
    text-transform: none;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
  }

  &__map {
    width: 100%;
    height: rem(1080/2);
  }
}
