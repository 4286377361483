form {
  box-sizing: border-box;
}

input[type=submit],
button {
  border: none;
}

input[type=text],
input[type=email],
input[type=number],
textarea,
select {
  -webkit-appearance: none; /* reset default ios form styles */
  box-shadow: none;
  outline: none;
  width: 100%;
  padding: 7px 28px 7px 14px;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 3px;
  background-color: #fff;
}

input[type=submit]:disabled,
button:disabled {
  background-color: rgba($color-blue, 0.3) !important;
  cursor: auto;
  &:hover {
    opacity: 1;
    background-color: rgba($color-blue, 0.3) !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  textarea {
      font-size: 16px !important;
    }
}
