%o-buttons {
  @include list-reset;
  margin-left: -(rem($global-spacing/12));
  text-align: center;
  @include bp-lg {
    text-align: inherit;
  }

  &__item {
    display: inline-block;
    margin-left: rem($global-spacing/12);
    margin-right: rem($global-spacing/12);
    margin-bottom: rem($global-spacing/6);
    @include bp-lg {
      margin-bottom: 0;
    }
  }
}
