.c-buttons {
  @include list-reset;
  font-size: 0;
  &__item {
    @extend .o-list-inline__item;
    @extend .u-mb--quater;
    @include bp-lg {
      margin-bottom: 0;
      & + & {
        margin-left: rem(20);
      }
    }
  }
}
