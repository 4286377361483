/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
  width: 100%; /* [1] */
}

table {
  width: 100%;
  border: none;
  vertical-align: top;
  text-align: left;

  thead,
  th {
    @extend .text--primary;
    @extend .text--extrabold;
    @extend .text--headingsmall;
  }

  td,
  th {
    padding-top: rem(8);
    padding-bottom: rem(8);
    padding-left: rem(12);
    vertical-align: top;
/*
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
*/
  }

  td {
    @extend .text--copysmall;
    @extend .text--utility;
  }

  tr:nth-child(odd) td,
  tr:nth-child(odd) th {
    background-color: $color-blue-light;
  }

}
