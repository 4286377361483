.c-footer {
  @extend .u-pt;
  @extend .u-fill--blue;

  &__legal {
    @extend .u-fill--dove-gray;
  }

  &__contact,
  &__legal {
    @extend .u-pt;
    @extend .u-pb;
  }

  &__links {
    @extend .u-pb;
  }

  &__divider {
    @extend .u-mt;
    border-bottom: 2px solid $color-white;
  }

  &__section {
    @include grid-container;
  }

  &__row {
    @include grid-row;
  }

  &__title {
    @extend %c-headline__title;
    @extend .text--calloutsmall;
    @extend .text--white;
  }
  &__subtitle {
    @extend %c-headline__subtitle;
    @extend .text--white;
  }

  &__copy {
    @include grid-column(12);
    color: $color-white;
    @include bp-lg {
      @include grid-column(4);
    }
  }

  &__copytext {
    @extend .u-mb;
  }

  &__form {
    @include grid-column(12);
    @extend .u-pt;
    @include bp-lg {
      @include grid-column(8);
      padding-top: 0;
    }
  }

  &__navigation {
    @include bp-lg {
      @include flex();
      @include flex-vertical-center();
    }
  }

  &__navigation,
  &__author,
  &__ukwinformation,
  &__ukwlogo {
    @include grid-column(12);
    text-align: center;
    @include bp-lg {
      text-align: inherit;
    }
  }

  &__copyright,
  &__navigation,
  &__ukwinformation {
    @extend .u-mb--quater;
    @include bp-lg {
      margin-bottom: 0;
    }
  }

  &__navigation,
  &__ukwinformation {
    @include bp-lg {
      @include grid-column(9);
    }
  }

  &__author,
  &__ukwlogo {
    @include bp-lg {
      @include grid-column(3);
    }
  }

  &__author {
    @include bp-lg {
      text-align: right;
    }
  }

  &__copyright {
    @extend .text--primary;
    @extend .text--copysmall;
    @extend .text--extrabold;
    color: $color-white;
    @include bp-lg {
      margin-right: 0.5em;
    }
  }

}
