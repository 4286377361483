.wpcf7-validates-as-required.wpcf7-not-valid {
  border-color: red;
}
.c-footer-form {
  .wpcf7-response-output {
    @extend .u-mb--quater;
    @extend .u-pl--quater;
    @extend .u-pt--quater;
    @extend .u-pb--quater;
    border-color: $color-white;
    color: $color-white;
    border-radius: 55px;
    border: none;
  }
  div.wpcf7-mail-sent-ok {
    background-color: #95c700;
  }
  div.wpcf7-validation-errors,
  div.wpcf7-acceptance-missing {
    background-color: #d71440;
  }
}
